// ** React Import
import { ReactNode } from "react";

// ** Next Import
//import Link from 'next/link'

// ** MUI Imports
import IconButton from "@mui/material/IconButton";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";

// ** Icons
import Close from "mdi-material-ui/Close";

// ** Type Import
import { Settings } from "src/@core/context/settingsContext";
import { Link } from "react-router-dom";
import Image from "mui-image";
import Logo from "src/images/boreas-full-logo.png";

import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

interface Props {
  hidden: boolean;
  navHover: boolean;
  settings: Settings;
  collapsedNavWidth: number;
  menuLockedIcon?: ReactNode;
  menuUnlockedIcon?: ReactNode;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  verticalNavMenuBranding?: (props?: any) => ReactNode;
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: theme.spacing(4.5),
  transition: "padding .25s ease-in-out",
  minHeight: "72px",
}));

const StyledLink = styled("a")({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    menuUnlockedIcon: userMenuUnlockedIcon,
    verticalNavMenuBranding: userVerticalNavMenuBranding,
  } = props;

  // ** Hooks & Vars
  const { navCollapsed } = settings;

  const menuCollapsedStyles =
    navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 };
  const theme = useTheme();

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userVerticalNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 32) / 8;
      }
    } else {
      return 6;
    }
  };

  const MenuLockedIcon = () =>
    userMenuLockedIcon || (
      <MenuOpenRoundedIcon
        sx={{
          fontSize: "2rem",
          pointerEvents: "none",
          ...menuCollapsedStyles,
          transition: "opacity .25s ease-in-out",
        }}
      />
    );

  const MenuUnlockedIcon = () =>
    userMenuUnlockedIcon || (
      <MenuRoundedIcon
        sx={{
          fontSize: "2rem",
          pointerEvents: "none",
          transition: "opacity .25s ease-in-out",
        }}
      />
    );

  return (
    <MenuHeaderWrapper
      height={72}
      className="nav-header"
      sx={{ pl: menuHeaderPaddingLeft() }}
    >
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <Link to="/">
          <StyledLink>
            <Box
              sx={{
                display: navCollapsed && !navHover ? "none" : "flex",
                transition: "display .25s ease-in-out",
              }}
              alignItems="center"
            >
              <img src={Logo} width={"auto"} height={50} />
            </Box>
          </StyledLink>
        </Link>
      )}

      {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={toggleNavVisibility}
          sx={{ p: 0, backgroundColor: "transparent !important" }}
        >
          <Close fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() =>
            saveSettings({ ...settings, navCollapsed: !navCollapsed })
          }
          sx={{
            p: 0,
            color: "text.primary",
            backgroundColor: "transparent !important",
          }}
        >
          {navCollapsed ? MenuUnlockedIcon() : MenuLockedIcon()}
        </IconButton>
      )}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
