
// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical'

// ** Third Party Imports
import { ApexOptions } from 'apexcharts'

// ** Custom Components Imports
import ReactApexcharts from 'src/@core/components/react-apexcharts'

const series = [
  {
    name: 'Sensor A',
    data: [290, 220, 250, 185, 290, 200, 345]
  },
  {
    name: 'Sensor B',
    data: [0, 160, 110, 155, 0, 120, 95]
  },
  {
    name: 'Sensor C',
    data: [0, 0, 0, 140, 0, 110, 120]
  }
]

const CardWidgetsTotalProfitStackedBar = () => {
  // ** Hook
  const theme = useTheme()

  const options: ApexOptions = {
    chart: {
      stacked: true,
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '35%',
        //endingShape: 'rounded',
        //startingShape: 'rounded'
      }
    },
    xaxis: {
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J']
    },
    yaxis: {
      labels: {
        formatter: (value: number) => (value > 999 ? `${(value / 1000).toFixed(0)}` : `${value}`)
      }
    },
    colors: [theme.palette.primary.main, theme.palette.success.main, theme.palette.secondary.main],
    grid: {
      strokeDashArray: 7,
      padding: {
        bottom: -10
      }
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    stroke: {
      width: 6,
      curve: 'smooth',
      lineCap: 'round',
      colors: [theme.palette.background.paper]
    },
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '45%'
            }
          }
        }
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '50%'
            }
          }
        }
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '45%'
            }
          }
        }
      },
      {
        breakpoint: 430,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '55%'
            }
          }
        }
      }
    ]
  }

  return (
    <Card>
      <Grid container>
        <Grid item xs={12}>
          <CardHeader
            title='Module Status Evolution'
            titleTypographyProps={{
              sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
            }}
            action={
              <IconButton
                size='small'
                aria-label='settings'
                className='card-more-options'
                sx={{ color: 'text.secondary' }}
              >
                <DotsVertical />
              </IconButton>
            }
          />
          <CardContent sx={{
            height: '100%',
            '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 },
            '& .apexcharts-yaxis-label': {
              fill: `${theme.palette.text.primary} !important`,
            },
            '& .apexcharts-xaxis-label': {
              fill: `${theme.palette.text.primary} !important`,
            },
            '& .apexcharts-tooltip': {
              color: `${theme.palette.text.primary} !important`,
            },
            '& .apexcharts-tooltip-title': {
              color: `${theme.palette.text.primary} !important`,
              backgroundColor: `${theme.palette.background.paper} !important`,
              border: 0
            },
            '& .apexcharts-tooltip-box': {
              color: `${theme.palette.text.primary} !important`,
              backgroundColor: `${theme.palette.background.paper} !important`,
            },
            '& .apexcharts-tooltip .apexcharts-tooltip-series-group': {
              color: `${theme.palette.text.primary} !important`,
              backgroundColor: `${theme.palette.background.paper} !important`,
            }
          }}>
            <ReactApexcharts type='bar' height={305} series={series} options={options} />
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CardWidgetsTotalProfitStackedBar
