// ** React Imports
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

// ** Next Imports

// ** MUI Imports
import Grid, { GridProps } from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {
  CardContent,
  InputLabel,
  Switch,
  CardActions,
  Chip,
  Typography,
  CardHeader,
  IconButton,
  Collapse,
  Box,
  Stack,
  styled,
  TypographyProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  BoxProps,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { ChevronDown, ChevronUp } from "mdi-material-ui";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
// ** Library Imports
import { Field, Form, Formik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MuiTab, { TabProps } from "@mui/material/Tab";

// ** Icon Imports
import FDefaultCurrencyTextField from "src/@core/components/form-field/DefaultCurrencyTextField";
import FButtonToggle from "src/@core/components/form-field/FButtonToggleTrain";
import FButtonToggleState from "src/@core/components/form-field/FButtonToggleState";
import FButtonToggleLayout from "src/@core/components/form-field/FButtonToggleLayout";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import AssetInfoCard from "src/@core/components/card/AssetInfoCard";
import { AssetType } from "src/types/graphql/assetType";
import { useAssetsLazyQuery } from "src/generated/graphql";
import LastestReportsCard from "src/@core/components/card/LastestReportsCard";
import { ModalContext } from "src/context/modalContext";
import FButtonToggleTrain from "src/@core/components/form-field/FButtonToggleTrain";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CustomAvatar from "src/@core/components/mui/avatar";

import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import MyMap from "src/@core/components/map/MyMap";
import DepartmentAMap from "src/@core/components/map/DepartmentAMap";
import DepartmentBMap from "src/@core/components/map/DepartmentBMap";

// ** Onchange Component
const AutoChange = (props: any) => {
  const { values, setFieldValue } = useFormikContext<any>();

  return null;
};

// ** Styled Components
const TotalText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  color: hexToRGBA(theme.palette.customColors.CurrentText, 0.6),
}));

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(3),
  },
}));

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  position: "sticky",
  top: 56,
  zIndex: 5,
  backgroundColor: theme.palette.background.default,
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85),
}));

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 7,
  padding: 10,
}));

const DepartmentsViewPage = () => {
  const [Assets, { loading }] = useAssetsLazyQuery();

  // ** States
  const [active, setActive] = useState("");
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [getEnableForProfitRate, setGetEnableForProfitRate] =
    useState<boolean>(false);
  const [visibleOfFavorites, setVisibleOfFavorites] = useState<boolean>(false);
  const [visibleOfMinQuantity, setVisibleOfMinQuantity] =
    useState<boolean>(false);
  const [layoutNumber, setLayoutNumber] = useState<number>(4);
  const [assets, setAssets] = useState<AssetType[]>([]);
  const [value, setValue] = useState<string>("a-department");

  let initialValues: any;

  // by passing empty array at the end, this will always return the same function, compatible with removeEventListener
  const keyDownHandler = useCallback((event: any) => {
    if (event.keyCode === 13 && event.target.nodeName !== "BUTTON") {
      event.preventDefault();
    }
  }, []);

  const fetchTableData = useCallback(async () => {
    await Assets({
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setTotalRowCount(res.data?.assets.length || 0);
      setAssets(res.data?.assets || ([] as AssetType[]));
    });
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  const onBack = () => {
    //router.back();
  };

  const submitForm = async (values: any) => {};

  const { setLocationModal, locationModal } = useContext(ModalContext);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // ** Components
  const LocationModal = React.memo(() => {
    return (
      <Dialog
        fullWidth
        open={locationModal}
        maxWidth="md"
        scroll="body"
        onClose={() => setLocationModal(false)}
      >
        <DialogTitle sx={{ p: 0, mb: 5 }}>
          <Title p={5}>Asset Location Detail</Title>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "10px !important" }}>
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FButtonToggleTrain></FButtonToggleTrain>
              <Typography variant="subtitle1" color="primary">
                RE484 - New York
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomAvatar
                  skin="light"
                  color="error"
                  sx={{
                    mr: 3.75,
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "7px !important",
                  }}
                >
                  <SensorsRoundedIcon />
                </CustomAvatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    mx: 2,
                  }}
                >
                  <Typography variant="subtitle2">Alarm Status</Typography>
                  <Typography variant="subtitle2">Hihg Level</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TabContext value={value}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  aria-label="forced scroll tabs example"
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Tab value="detail" label="Detail" />
                  <Tab value="download" label="Download" />
                  <Tab value="link" label="Link" />
                </TabList>
                <Box sx={{ mt: 6 }}>
                  <TabPanel sx={{ p: 0 }} value="detail">
                    <Box width={"100%"}>
                      <YMaps>
                        <Map
                          className="mappp"
                          defaultState={{
                            center: [45.75, 17.57],
                            zoom: 12,
                            controls: ["zoomControl", "fullscreenControl"],
                          }}
                          modules={[
                            "control.ZoomControl",
                            "control.FullscreenControl",
                          ]}
                        >
                          <Placemark
                            modules={["geoObject.addon.balloon"]}
                            defaultGeometry={[55.75, 37.57]}
                            properties={{
                              balloonContentBody:
                                "This is balloon loaded by the Yandex.Maps API module system",
                            }}
                          />
                        </Map>
                      </YMaps>
                    </Box>
                    <Grid container spacing={5} mt={7}>
                      <Grid item xs={12}>
                        <StyledBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            direction: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item container spacing={3} pt={0}>
                            <Grid
                              mt={3}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">
                                Device Name
                              </Typography>
                              <Typography variant="subtitle2">
                                Test Sensor
                              </Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">
                                Device Code
                              </Typography>
                              <Typography variant="subtitle2">RE484</Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  m: 1,
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="subtitle2">
                                  Date
                                </Typography>

                                <Box sx={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <EventNoteRoundedIcon
                                      fontSize="small"
                                      sx={{ m: 1 }}
                                    />
                                    <Typography variant="subtitle1">
                                      {"05.23.2023"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeRoundedIcon
                                      fontSize="small"
                                      sx={{ m: 1, ml: 5 }}
                                    />
                                    <Typography variant="subtitle1">
                                      {"01:13"}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">
                                Error Detail
                              </Typography>
                              <Typography color="error" variant="subtitle2">
                                High Level (Vibration 8), V08, High level axle
                                failure
                              </Typography>
                            </Grid>
                          </Grid>
                        </StyledBox>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel sx={{ p: 0 }} value="download">
                    <Box width={"100%"}>
                      <YMaps>
                        <Map
                          className="mappp"
                          defaultState={{
                            center: [55.75, 37.57],
                            zoom: 12,
                            controls: ["zoomControl", "fullscreenControl"],
                          }}
                          modules={[
                            "control.ZoomControl",
                            "control.FullscreenControl",
                          ]}
                        >
                          <Placemark
                            modules={["geoObject.addon.balloon"]}
                            defaultGeometry={[55.75, 37.57]}
                            properties={{
                              balloonContentBody:
                                "This is balloon loaded by the Yandex.Maps API module system",
                            }}
                          />
                        </Map>
                      </YMaps>
                    </Box>
                    <Grid container spacing={5} mt={7}>
                      <Grid item xs={12}>
                        <StyledBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            direction: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item container spacing={3} pt={0}>
                            <Grid
                              mt={3}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">Route</Typography>
                              <Typography variant="subtitle2">
                                Device
                              </Typography>
                              <Typography variant="subtitle2">
                                Begin Time
                              </Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">
                                Bazel - Muttanz
                              </Typography>
                              <Typography variant="subtitle2">RE484</Typography>
                              <Typography variant="subtitle2">
                                05.23.2023 - 01:13:53
                              </Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">
                                Bazel - Muttanz
                              </Typography>
                              <Typography variant="subtitle2">RE484</Typography>
                              <Typography variant="subtitle2">
                                05.23.2023 - 01:13:53
                              </Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">
                                Bazel - Muttanz
                              </Typography>
                              <Typography variant="subtitle2">RE484</Typography>
                              <Typography variant="subtitle2">
                                05.23.2023 - 01:13:53
                              </Typography>
                            </Grid>
                          </Grid>
                        </StyledBox>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel sx={{ p: 0 }} value="link">
                    <Box width={"100%"}>
                      <YMaps>
                        <Map
                          className="mappp"
                          defaultState={{
                            center: [55.75, 37.57],
                            zoom: 12,
                            controls: ["zoomControl", "fullscreenControl"],
                          }}
                          modules={[
                            "control.ZoomControl",
                            "control.FullscreenControl",
                          ]}
                        >
                          <Placemark
                            modules={["geoObject.addon.balloon"]}
                            defaultGeometry={[55.75, 37.57]}
                            properties={{
                              balloonContentBody:
                                "This is balloon loaded by the Yandex.Maps API module system",
                            }}
                          />
                        </Map>
                      </YMaps>
                    </Box>
                    <Grid container spacing={5} mt={7}>
                      <Grid item xs={12}>
                        <StyledBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            direction: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item container spacing={3} pt={0}>
                            <Grid
                              pt={4}
                              item
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  m: 1,
                                }}
                              >
                                <Typography variant="subtitle2">
                                  Name
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button variant="text" color="primary">
                                  <DownloadRoundedIcon sx={{ mr: 1.5 }} />{" "}
                                  Download
                                </Button>
                              </Box>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography color="error" variant="subtitle2">
                                High_2022_11_28_01_13_43.hds
                              </Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">RE484</Typography>
                            </Grid>
                            <Divider sx={{ width: "100%" }} />
                            <Grid
                              py={0}
                              item
                              px={2}
                              xs={12}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="subtitle2">RE484</Typography>
                            </Grid>
                          </Grid>
                        </StyledBox>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </TabContext>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "end",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setLocationModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  return (
    <>
      <TabContext value={value}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="forced scroll tabs example"
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Tab value="a-department" label="A Deparment" />
          <Tab value="b-department" label="B Department" />
        </TabList>
        <Box sx={{ mt: 3 }}>
          <TabPanel sx={{ p: 0 }} value="a-department">
            <Box width={"100%"}>
              <DepartmentAMap />
            </Box>
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="b-department">
          <Box width={"100%"}>
              <DepartmentBMap />
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
};

export default DepartmentsViewPage;
