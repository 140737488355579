import { Tab } from '@headlessui/react'
import React from 'react'

export const AssetTab = () => {
  return (
    <Tab.Group>
      <Tab.List className='flex bg-[#2E385A] justify-evenly h-12'>
        <Tab>
          {({ selected }) => (
            /* Use the `selected` state to conditionally style the selected tab. */
            <span
            className={`p-1 font-bold ${selected ? ' text-[#38E7FF] border-[#38E7FF] border-b-2' : 'text-[#99A5CB]'}`

              }
            >
              FIGURE
            </span>
          )}
        </Tab>
        <Tab>
          {({ selected }) => (
            /* Use the `selected` state to conditionally style the selected tab. */
            <span
            className={`p-1 font-bold ${selected ? ' text-[#38E7FF] border-[#38E7FF] border-b-2' : 'text-[#99A5CB]'}`

              }
            >
              DIGITAL TWINS
            </span>
          )}
        </Tab>
        <Tab>
          {({ selected }) => (
            /* Use the `selected` state to conditionally style the selected tab. */
            <span
            className={`p-1 font-bold ${selected ? ' text-[#38E7FF] border-[#38E7FF] border-b-2' : 'text-[#99A5CB]'}`
        }
            >
              MODULES VIEW
            </span>
          )}
        </Tab>
        <Tab>
          {({ selected }) => (
            /* Use the `selected` state to conditionally style the selected tab. */
            <span
              className={`p-1 font-bold ${selected ? ' text-[#38E7FF] border-[#38E7FF] border-b-2' : 'text-[#99A5CB]'}`
                
              }
            >
              LOCATION
            </span>
          )}
        </Tab>
        
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel><img className='w-full' src='/images/test-train.jpeg' alt="" /></Tab.Panel>
        <Tab.Panel><img className='w-full' src='/images/digital.svg' alt="" /></Tab.Panel>
        <Tab.Panel><img className='w-full' src='/images/bogi.png' alt="" /></Tab.Panel>
        <Tab.Panel><img className='w-full' src='/images/map.svg' alt="" /></Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
}
