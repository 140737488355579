import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "./MyMap.css";
import sectionsData from "./sectionsData.json";
import stationsData from "./stationsData.json";
import { Grid, Typography } from "@mui/material";
import PRailCrack from "src/images/boreas-img.jpeg";

const DepartmentAMap = (
  {
    //mapIsReadyCallback /* To be triggered when a map object is created */,
  }
) => {
  const position = [40.732239, -73.8795];

  const filteredSectionsData = {
    type: "FeatureCollection",
    features: sectionsData.features.filter((item) =>
      item.properties.lines.find(
        (i) => i.line_url_name === "1313-rockaway-line"
      )
    ),
  };

  const filteredStationsData = {
    type: "FeatureCollection",
    features: stationsData.features.filter((item) =>
      item.properties.lines.find(
        (i) => i.line_url_name === "1313-rockaway-line"
      )
    ),
  };

  console.log(filteredStationsData);

  return (
    <MapContainer
      style={{ height: "590px" }}
      center={{ lat: position[0], lng: position[1] }}
      zoom={11}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/*<GeoJSON data={filteredStationsData as GeoJSON.FeatureCollection} markersInheritOptions={true} /> */}
      <Marker position={{ lat: 40.784539, lng: -73.9704 }}>
        <Popup >
          <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
              <Typography>Fan Motor 1</Typography>
              <Typography>lat: 40.784539</Typography>
              <Typography>lng:-73.970400</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.684539, lng: -73.9704 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
            <Typography>Fan Motor 2</Typography>
            <Typography>lat: 40.684539</Typography>
              <Typography>lng:-73.9704</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.684539, lng: -73.8304 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'}src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
            <Typography>Fan Motor 3</Typography>
            <Typography>lat: 40.684539</Typography>
              <Typography>lng: -73.8304 </Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.594539, lng: -73.7704 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
            <Typography>Fan Motor 4</Typography>
            <Typography>lat: 40.594539</Typography>
              <Typography>lng: -73.7704</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default DepartmentAMap;
