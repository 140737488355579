import {MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import './MyMap.css';
import sectionsData from './sectionsData.json'
import stationsData from './stationsData.json'

const TrainLocation = ({
  //mapIsReadyCallback /* To be triggered when a map object is created */,
}) => {
    const position = [40.732239, -73.879500]

    const filteredSectionsData = {
        type: 'FeatureCollection',
        features: sectionsData.features.filter((item => item.properties.lines.find(i => i.line_url_name === '1313-rockaway-line')))
    };

    const filteredStationsData = {
        type: 'FeatureCollection',
        features: stationsData.features.filter((item => item.properties.lines.find(i => i.line_url_name === '1313-rockaway-line')))
    };

    console.log(filteredStationsData);

    return (<MapContainer style={{ height: '590px' }} center={{ lat: position[0], lng: position[1]}} zoom={11} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {/*<GeoJSON data={filteredStationsData as GeoJSON.FeatureCollection} markersInheritOptions={true} /> */}
    <Marker position={{lat: 40.684539, lng:-73.970400}}>
      <Popup>
      </Popup>
    </Marker>
  </MapContainer>)
};

export default TrainLocation;
